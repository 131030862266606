import React, { useContext } from 'react';
import { Table } from '../../components/Table';
import { Context } from '../../functions/servest_is/context';

export const ViewClosedWorkOrders = () => {
  const { username } = useContext(Context);

  document.title = 'Closed WorkOrders | Servest IS Customer Portal';

  return (
    <Table
      Context={Context}
      title="Closed WorkOrders"
      tableModelClass="WorkOrder"
      id="workorder"
      columnView="Customer WorkOrders View"
      filters={[{
        property: 'customer.user.username',
        value: username,
        options: [],
      },
      {
        property: 'workOrderStatus.open',
        value: false,
        options: [],
      }]}
      sorts={{
        property: 'createdDateTime',
        order: 'DESC',
      }}
    />
  );
};
