import React, { useContext } from 'react';
import { Table } from '../../components/Table';
import { Context } from '../../functions/servest_is/context';

export const ViewClosedTickets = () => {
  const { username } = useContext(Context);

  document.title = 'Closed Tickets | Servest IS Customer Portal';

  return (
    <Table
      Context={Context}
      title="Closed Tickets"
      tableModelClass="WorkOrderRequest"
      id="workorderrequest"
      columnView="Customer WorkOrderRequest View"
      filters={[{
        property: 'customer.user.username',
        value: username,
        options: [],
      },
      {
        property: 'workRequestStatus.open',
        value: false,
        options: [],
      }]}
      sorts={{
        property: 'dateCreated',
        order: 'DESC',
      }}
    />
  );
};
