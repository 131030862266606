import React from 'react';
import {
  Button, Form, Card,
} from 'react-bootstrap';
import * as Material from '@material-ui/core';
import { CircularProgress as Spinner } from '@material-ui/core';
import { LoginFormContainer } from './joburgwater.styled';
import * as login from '../../scripts/loginScripts';
import Ripple from './ripple';
import SlideIn from './slidein';

export class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      submitting: false,
      loginDone: false,
      passwordDisplay: 'password',
    };
  }

  handleLogin = () => {
    console.log('handling login');
    const { onLogin } = this.props;
    this.setState({ submitting: true });
    onLogin(this.state, (submitting, loginDone) => this.setState({ submitting, loginDone }));
  };

  render() {
    const {
      username, submitting, loginDone, passwordDisplay,
    } = this.state;
    const { subscriberId, withRegister = false } = this.props;

    return (
      <LoginFormContainer>
        <div className="slide-container">
          <SlideIn settings={{
            marginRight: 0,
            width: '100%',
            from: { marginRight: -700 },
            config: { mass: 1, tension: 300, friction: 30 },
          }}
          >
            <Card id="loginFormCard" className="text-center">
              <Card.Header>
                <img src="joburg_water/logo.jpg" className="login-logo" alt="Joburg Water Logo" />
                <div className="login-title">
                  Fault Logging
                </div>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group>
                    <Material.TextField
                      name="username"
                      fullWidth
                      label="Mobile number"
                      variant="outlined"
                      onChange={(event) => this.setState({ username: event.target.value })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Material.TextField
                      fullWidth
                      label="Password"
                      variant="outlined"
                      type={passwordDisplay}
                      onChange={(event) => this.setState({ password: event.target.value })}
                    />
                  </Form.Group>
                  <div className="button-group">
                    <Form.Group id="submit-spinner">
                      {!submitting && (
                      <Button
                        className="primary"
                        type="submit"
                        disabled={loginDone}
                        onClick={this.handleLogin}
                      >
                        Login
                        <Ripple />
                      </Button>
                      )}
                      {submitting && <Spinner />}
                    </Form.Group>
                    {withRegister && (
                    <Form.Group>
                      <Button className="secondary" onClick={() => { window.location.href = '/joburg_water/register'; }}>
                        Register
                        <Ripple />
                      </Button>
                    </Form.Group>
                    )}
                  </div>
                </Form>
              </Card.Body>
              <Card.Footer>
                <Material.Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    login.passwordReset(username, subscriberId);
                  }}
                  underline="always"
                >
                  Forgot password?
                </Material.Link>
                <Material.Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open('https://www.forcelink.net/support');
                  }}
                  underline="always"
                >
                  Log Support Issue
                </Material.Link>
                <Material.Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    login.passwordHint(username, subscriberId);
                  }}
                  underline="always"
                >
                  SMS Password Hint
                </Material.Link>
              </Card.Footer>
            </Card>
          </SlideIn>
        </div>
      </LoginFormContainer>
    );
  }
}
